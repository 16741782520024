import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "./plugins/font-awesome";
import NProgress from "nprogress";

// Initialize Crisp chat
// if (!window.$crisp) {
//   window.$crisp = [];
// }

// window.CRISP_WEBSITE_ID = "988676f5-ed1b-46af-8284-55cbe9c7652a";

(function () {
  const s = document.createElement("script");
  s.src = "https://client.crisp.chat/l.js";
  s.async = 1;
  document.head.appendChild(s);
})();

createApp(App)
  .use(store)
  .use(router)
  .use(NProgress)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
